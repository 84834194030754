/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* common */
export const COMMON_NOTIFICATION = "COMMON_NOTIFICATION";
export const GET_BALANCE = "GET_BALANCE";
export const SUBSCRIPTION_INFO = "SUBSCRIPTION_INFO";
export const GET_TARIFF = "GET_TARIFF";
export const CHECK_SUBSCRIPTION = "CHECK_SUBSCRIPTION";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_NOTIFICATION = "LOGIN_NOTIFICATION";
export const VERIFY_USER = "VERIFY_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";
export const GUEST_USER_SUCCESS = "GUEST_USER_SUCCESS";
export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const VERIFY_RESET_LINK = "VERIFY_RESET_LINK";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const VERIFY_NUMBER_SUCCESS = "VERIFY_NUMBER_SUCCESS";

export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const DEMO_FORM = "DEMO_FORM";
export const DEMO_LIST = "DEMO_LIST";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* Account */
export const GET_USER_DETAILS = "get_user_details";

export const GET_CITY = "get_city";
export const INSERT_CUSTOMER = "insert_customer";
export const UPDATE_CUSTOMER = "update_customer";
export const MANAGE_SIM_DETAILS = "manage_sim_details";

/* cart */

export const ADD_CART = "add_cart";
export const UPDATE_CART = "update_cart";
export const GET_CART = "get_cart";
export const GET_USER_CART = "get_user_cart";
export const REMOVE_CART = "remove_cart";
export const REMOVE_USER_CART = "remove_user_cart";
export const CART_NOTIFICATION = "CART_NOTIFICATION";

/* payment */
export const STORE_PAYMENT = "store_payment";
export const SEND_PAYMENT = "send_payment";
export const GET_CRMUSER_VOUCHER = "get_crmuser_voucher";
export const GET_CRMUSER_VOUCHER_TOPUP = "get_crmuser_voucher_topup";

/* customer support */
export const GET_TICKET_TYPES = "get_ticket_types";
export const MANAGE_SUPPORT_QUESTION = "manage_support_question";
export const MANAGE_SUPPORT_RESPONSE = "manage_support_response";
export const GET_USER_TICKET = "get_user_ticket";
export const TICKET_NOTIFICATION = "TICKET_NOTIFICATION";

/* dashboard pages */
export const COUNTER_QUERY = "counter_query";
export const CUSTOMER_COUNTER_QUERY = "customer_counter_query";
export const CHANGE_OPTION = "change_option";
export const GET_ANSWER_TICKET = "get_answer_ticket";
export const CHANGE_STATUS = "change_status";

export const GET_CALL_RECORD = "get_call_record";
export const GET_PADS_RECORD = "get_pads_record";
export const GET_SUBSCRIPTION_RECORD = "get_subscription_record";
export const GET_ACCOUNT_COUNT_RECORD = "get_account_count_record";
export const GET_ADJUSTMENT_TYPE_TICKET = "get_adjustment_type_record";
export const GET_SMS_RECORD = "get_sms_record";
export const GET_CDR_TYPE = "get_cdr_type";
export const INSERT_CDR_REPORT = "insert_cdr_report";
export const GET_CRM_VOUCHER_TOPUP_REPORT = "get_crm_voucher_topup_report";

/* portIn Request */
export const GET_PORTIN_LIST = "GET_PORTIN_LIST";
export const GET_PORTIN_STATUS = "GET_PORTIN_STATUS";
export const MANAGE_MNP_PORTIN = "MANAGE_MNP_PORTIN";
export const PORTIN_REQUEST_NOTIFICATION = "PORTIN_REQUEST_NOTIFICATION";
