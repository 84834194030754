import {
    COMMON_NOTIFICATION,
    GET_BALANCE,
    GET_TARIFF,
    SUBSCRIPTION_INFO,
    CHECK_SUBSCRIPTION,
  } from '../constants';
  
  const initialState = { 
    common_notification:{
      message: '',
      isSuccess: 1
    },
    getBalanceData: {},
    subscriptionInfo: {},
    getTariffPalnData: [],
    checkSubscriptionDetails: {},
  }
  
  const reducer = (state = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
  
      case COMMON_NOTIFICATION:
      return { ...state, 
        common_notification: (payload.message) ? payload : initialState.common_notification
      }

      case GET_TARIFF: {
        return {
          ...state,
          getTariffPalnData: payload,
        };
      }

      case GET_BALANCE: {
        return {
          ...state,
          getBalanceData: payload,
        };
      }

      case SUBSCRIPTION_INFO:
      return {
        ...state,
        subscriptionInfo: payload,
      };

      case CHECK_SUBSCRIPTION: {
        return {
          ...state,
          checkSubscriptionDetails: payload,
        };
      }
    
      default:
        return state;
    }
  }
  
  export default reducer;