import { getCartSession } from 'utils/CartDetails';
import {
  ADD_CART,
  UPDATE_CART,
  GET_CART,
  GET_USER_CART,
  REMOVE_CART,
  STORE_PAYMENT,
  CART_NOTIFICATION,
  SEND_PAYMENT,
  REMOVE_USER_CART,
  GET_CRMUSER_VOUCHER,
  GET_CRMUSER_VOUCHER_TOPUP,
} from '../constants';
import { getGuestRole, getUser } from 'utils/UserDetails';

const initialState = {
  cartItem: getCartSession()?.length ? getCartSession() : [],
  userCartItem: [],
  paymentDetails: [],
  sendPaymentDetails: [],
  cart_notification: {
    message: '',
    isSuccess: 1
  },
  crmUserVouher: {},
  crmUserVouherTopup: {},
}

const reducer = (state = initialState, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {

    case GET_CRMUSER_VOUCHER: {
      return {
        ...state,
        crmUserVouher: payload,
      };
    }

    case GET_CRMUSER_VOUCHER_TOPUP: {
      return {
        ...state,
        crmUserVouherTopup: payload,
      };
    }

    case GET_CART: {
      return {
        ...state,
        cartItem: payload,
      }
    }

    case GET_USER_CART: {
      return {
        ...state,
        userCartItem: payload,
      }
    }

    case ADD_CART: {

      if (getUser() !== null || getGuestRole() !== undefined) {

        return {
          ...state,
          userCartItem: [...state.userCartItem, payload]
        }
      } else {
        let product = false;

        if (state.cartItem?.length) {

          product = state.cartItem?.some(p => p.plantypeid === payload.plantypeid);
        }
        if (product === true) {

          return {
            cartItem: state.cartItem.map(item => {
              if (item.plantypeid === payload.plantypeid) {

                return payload
              }
              return item;
            })
          }
        }
        else {

          if (state.cartItem?.length) {
            return {
              cartItem: state.cartItem.map(item => {
                if (item.plantypeid !== payload.plantypeid) {

                  return payload
                }
                return item;
              })
            }
          } else {
            return {
              cartItem: [...state.cartItem, payload]
            }
          }
        }
      }
    }

    case CART_NOTIFICATION:
      return {
        ...state,
        cart_notification: (payload.message) ? payload : initialState.cart_notification
      }

    case REMOVE_USER_CART: {
      return {
        ...state,
        // userCartItem: state.userCartItem.filter(item => item.planid !== payload.planid)
        userCartItem: state.userCartItem.filter(item => { return !payload.includes(item.planid) })
      }
    }
    case REMOVE_CART: {
      return {
        ...state,
        cartItem: state.cartItem.filter(item => { return !payload.includes(item.planid) })
      }

    }

    case STORE_PAYMENT: {
      if (payload?.status === true) {
        return {
          ...state, userCartItem: '', paymentDetails: payload
        }
      }
      else {
        return { ...state, paymentDetails: payload }
      }
    }

    case SEND_PAYMENT: {
      return {
        ...state, sendPaymentDetails: payload
      }
    }

    default:
      return state;
  }
}

export default reducer;